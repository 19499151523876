<template>
  <QHeader/>
  <div class="relative flex flex-col">
    <section class="px-4">
      <div class="mx-auto max-w-7xl flex flex-col">
        <div class="p-2 lg:hidden">
          <QHeaderSearchForm/>
        </div>
        <QHeaderCategories/>
        <QHeaderHr/>
        <section class="hidden md:flex justify-end pb-5">
          <SelectButton
            :unselectable="false"
            :options="contentViewType"
            option-value="value"
            v-model="conf.contentViewType"
            class="category-view-switch"
            data-key="value">
            <template #option="slotProps">
              <i :class="slotProps.option.icon"></i>
            </template>
          </SelectButton>
        </section>
        <NuxtPage/>
      </div>
    </section>
  </div>
  <QDialogFeedback
    v-model="showFeedbackForm"
  />
  <QCartGlobal/>
  <QFooter/>
  <Toast group="app"/>
</template>

<script setup lang="ts">
import { ru } from 'date-fns/locale'
import { setDefaultOptions } from 'date-fns/setDefaultOptions'

setDefaultOptions({ locale: ru })
const router = useRouter()

const conf = reactive({
  contentViewType: 'grid'
})

provide('conf', conf)

onMounted(() => {
  if (process.client) {
    const viewType = window.localStorage.getItem('catalogViewType')
    if (viewType && viewType !== '') {
      conf.contentViewType = viewType
    }
  }
})

watch(() => conf.contentViewType, (viewType) => {
  if (process.client && viewType !== '') {
    window.localStorage.setItem('catalogViewType', viewType)
  }
})

const showFeedbackForm = ref(true)

const contentViewType = [
  {
    icon: 'pi pi-table',
    value: 'grid'
  },
  {
    icon: 'pi pi-list',
    value: 'table'
  }
]
</script>
