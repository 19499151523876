<template>
  <div class="rounded-full flex flex-wrap w-full bg-white lg:drop-shadow-lg" ref="el">
    <template :key="id" v-for="({id, name}) in categories">
      <NuxtLink
        v-text="name"
        :to="`/category/${id}`"
        :class="['lg:rounded-full cursor-pointer py-2 px-2 lg:py-4 lg:px-6', {'border-b-2 border-[#CE4792] lg:border-b-0 lg:bg-black lg:text-white': categoryId === id}]"/>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useElementHeightToCssVariable } from '~/composables/useElementHeightToCssVariable'

const categoriesStore = useCategoriesStore()
categoriesStore.fetch()
const categories = computed(() => categoriesStore.categories)
const route = useRoute()
const categoryId = computed(() => {
  const cId = route.params?.id
  if (route.params.id) {
    return cId.toString()
  } else if (Array.isArray(categoriesStore.categories) && categoriesStore.categories.length) {
    return categoriesStore.categories[0]?.id
  }
  return undefined
})

const { el } = useElementHeightToCssVariable('--q-header-categories-height')
</script>
