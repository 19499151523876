<script setup lang="ts">
import { useAuthenticatedFetch } from '~/composables/useAuthenticatedFetch'

const showDialog = ref(false)
const stepFeedbackForm = ref(0)

interface ShowDialogFeedbackProps {
  modelValue: boolean
}
const props = withDefaults(defineProps<ShowDialogFeedbackProps>(), {
  modelValue: true
})

const postFeedback = async () => {
  const { data: responseFeedback } = await useAuthenticatedFetch('/api/v1/shop/feedbacks', {
    method: 'POST',
    body: form,
    watch: false
  })
}

const form = reactive([
  {
    value: 0,
    type: 'recommendation'
  },
  {
    value: 0,
    text: '',
    type: 'order'
  },
  {
    value: 0,
    type: 'service'
  }
])

const emit = defineEmits<{
  (e: 'update:modelValue', v: boolean): void
}>()

const closeFeedback = () => {
  emit('update:modelValue',  false)
}

const isShowFeedbackCloseButton = ref(false)

watch(stepFeedbackForm, (v) => {
  if (v >= steps.length) {
    showDialog.value = false
    postFeedback()
    stepFeedbackForm.value = 0
    closeFeedback()
    clearFrom()
  }
})

const steps = reactive([
  {
    title: 'Пожалуйста, оцените, насколько вероятно, Вы порекомендуете нас своим друзьям или партнерам?',
    star: 5,
    field: 'value'
  },
  {
    title: 'Оцените текущий заказ',
    star: 5,
    field: 'value',
    comment: 'text'
  },
  {
    title: 'Оцените наш сервис',
    star: 5,
    field: 'value',
    comment: 'text'
  }
])

const clearFrom = () => {
  for (let step in steps) {
    form[step][steps[step].field] = 0

    if (steps[step].comment)
      form[step][steps[step].comment] = ''
  }
}
</script>

<template>
  <div v-if="modelValue" class="sticky w-full h-16 bottom-24 z-10">
    <div class="flex w-full justify-center xl:justify-end">
      <div class="w-72 h-16">
        <div
          class="relative h-16"
          @mouseenter="isShowFeedbackCloseButton = true"
          @mouseleave="isShowFeedbackCloseButton = false"
        >
          <Button
            unstyled
            v-show="isShowFeedbackCloseButton"
            class="absolute border-2 bottom-12 right-6 h-5 w-5 rounded-full bg-white"
            @click="closeFeedback"
          >
            <div class="w-full flex justify-center">
              <svg width="8" height="8" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.36426 1.36377L7.72822 7.72773M7.72822 7.72773L14.0922 14.0917M7.72822 7.72773L14.0922 1.36377M7.72822 7.72773L1.36426 14.0917"
                  stroke="#202020" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </Button>
          <Button
            unstyled
            class="absolute left-6 bottom-0 rounded-3xl text-white w-60 h-10 bg-gradient-to-r from-[#FF3E34] via-[#CE4792] to-[#2B89C4] hover:bg-[#CE4792] hover:bg-none"
            label="Помогите нам стать лучше!"
            @click="showDialog = true"
          />
        </div>
      </div>
    </div>
  </div>
  <Dialog
    modal
    v-model:visible="showDialog"
    :showHeader="false"
    :closable="false"
    :contentStyle="{'border-radius': '30px'}"
    class="mx-4 w-11/12 lg:w-2/6 rounded-3xl"
  >
    <Button
      unstyled
      class="fixed -top-12 right-0 h-9 w-9 rounded-full bg-white"
      @click="showDialog = false"
    >
      <div class="w-full flex justify-center">
        <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.36426 1.36377L7.72822 7.72773M7.72822 7.72773L14.0922 14.0917M7.72822 7.72773L14.0922 1.36377M7.72822 7.72773L1.36426 14.0917"
            stroke="#202020" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </Button>
    <div v-for="({title, star, field, comment}, i) in steps">
      <div class="p-4" v-if="i === stepFeedbackForm">
        <h1 class="font-bold md:text-xl mt-10">
          {{ title }}
        </h1>
        <p class="mt-6" v-if="i <= 0">
          1 - вряд ли, 5 - обязательно
        </p>
        <div class="w-full flex justify-center">
          <Rating
            v-model="form[stepFeedbackForm][field]"
            :cancel="false"
            :stars="star"
            class="flex flex-row gap-3 mt-10"
            :class="{'mb-20': !comment, 'mb-10': comment}"
          >
            <template #onicon>
              <img src="@/assets/onicon.svg" height="42" width="45" alt="star"/>
            </template>
            <template #officon>
              <img src="@/assets/officon.svg" height="42" width="45" alt="star"/>
            </template>
          </Rating>
        </div>
        <div class="flex justify-center mb-5" v-if="comment">
          <Textarea v-model="form[stepFeedbackForm][comment]" rows="5" cols="70"/>
        </div>
        <div class="flex justify-between">
          <Button
            :disabled="stepFeedbackForm === 0"
            class="p-2 rounded-3xl text-white w-5/12"
            label="Назад"
            @click="stepFeedbackForm--"
          />
          <Button
            unstyled
            class="p-2 rounded-3xl text-white w-5/12 bg-gradient-to-r from-[#FF3E34] via-[#CE4792] to-[#2B89C4]"
            :label="stepFeedbackForm !== steps.length - 1 ? 'Вперед' : 'Завершить'"
            @click="stepFeedbackForm++"
          />
        </div>
      </div>
    </div>
    <div class="fixed w-11/12 -bottom-24">
      <div class="flex w-full bg-[#D9D9D9] bg-opacity-30 h-1 rounded mt-8"
           :class="{'justify-start' : stepFeedbackForm === 0, 'justify-center' : stepFeedbackForm === 1, 'justify-end' : stepFeedbackForm === 2}">
        <div class="w-1/3 bg-white h-1 rounded"></div>
      </div>
      <div class="flex w-full mt-3">
        <div class="flex w-1/3 duration-300" :class="{'text-[#D9D9D9]' : stepFeedbackForm === 0}">
          <span class="text-4xl ">1.</span>
        </div>
        <div class="flex w-1/3" :class="{'text-[#D9D9D9]' : stepFeedbackForm === 1}">
          <span class="text-4xl duration-300">2.</span>
        </div>
        <div class="flex" :class="{'text-[#D9D9D9]' : stepFeedbackForm === 2}">
          <span class="text-4xl duration-300">3.</span>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<style>

</style>
